.nightMode #dialogs {
  background-color: rgb(8, 6, 15);

}
#dialogs {
  background-color: rgb(238, 235, 247);
  position: fixed;
  top: 5em;
  left: 0;
  width: 100%;
  bottom: 0;
  display: none;
}

.dialog {
  display: none;
  -webkit-transition: all .4s; /* Safari */
  transition: all .4s;
}



#youtubeDialog {
  height: 100%;
  text-align: left;
}

svg.bibleProjectSvg {
  width:175px;
}
